<template>
  <div>
    <v-chip
      v-if="type === 'list'"
      class="mb-1"
      :color="color"
      text-color="white"
      small
    >
      <slot></slot>
    </v-chip>
    <span
      v-if="type === 'detail'"
      :style="{ 'color': color }"
    >
      <v-icon
        class="mb-1 ml-1"
        :color="color"
      >mdi-clock</v-icon>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ba-sla-chip',
  props: {
    value: Number,
    sla: null,
    type: String
  },
  data () {
    return {
      color: '',
      slaParameters: []
    }
  },
  computed: {
    blackObject () {
      return this.slaParameters.find(param => param.key === 'NEGRO') || {}
    },
    orangeObject () {
      return this.slaParameters.find(param => param.key === 'AMARILLO') || {}
    },
    greenObject () {
      return this.slaParameters.find(param => param.key === 'VERDE') || {}
    },
    redObject () {
      return this.slaParameters.find(param => param.key === 'ROJO') || {}
    }
  },
  watch: {
    value: {
      handler (after) {
        this.getColor()
      },
      immediate: true
    }
  },
  methods: {
    getColor () {
      if (this.sla) {
        if (this.value >= this.greenObject.value) {
          this.color = this.greenObject.color
        } else if (this.value >= this.orangeObject.value) {
          this.color = this.orangeObject.color
        } else if (this.value >= 0) {
          this.color = this.redObject.color
        } else {
          this.color = this.blackObject.color
        }
      } else {
        this.color = ''
      }
    }
  },
  beforeMount () {
    this.slaParameters = this.$store.state.parameter.slaParameters
    this.getColor()
  }
}
</script>

<style scoped>
</style>
