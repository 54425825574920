<template>
  <div class="text-center my-5">
    <v-pagination
      v-model="page"
      :length="params.cantPages"
      circle
      :total-visible="7"
      @input="update"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    params: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      page: 1
    }
  },
  methods: {
    update () {
      this.params.pagina = this.page
      this.$emit('reload', this.params)
    }
  }
}
</script>

<style>
</style>
