<template>
  <v-row class="title mx-1">
    <v-col cols="8" md="8">
      <h5 class="my-auto"> {{ toolbarProps.title }}</h5>
    </v-col>
    <v-col
      cols="4"
      md="4"
      class="d-flex justify-end my-auto"
    >
      <v-tooltip
        bottom
        v-for="(item, index) of toolbarProps.actions"
        :key="index"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            class="mx-1"
            fab
            dark
            x-small
            :color="item.color"
            @click="$emit(item.type)"
          >
            <v-icon dark>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{item.label}}</span>
      </v-tooltip>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ba-toolbar',
  props: {
    toolbarProps: {
      type: Object,
      default: null
    }
  }

}
</script>

<style>
.title {
  border-bottom: 1px solid #dae1e7;
}
</style>
