<template>
  <v-card
    :hover="hover"
    @click="setItem(item)"
  >
    <!--begin: contact header-->
    <v-col
      v-if="styleType === 'contact'"
      class="ba-card-header"
    >
      <div>
        <v-avatar
          color="primary"
          size="36"
        >
          <span class="white--text">{{ getAvatar(item) }}</span>
        </v-avatar>
        <span class="mx-2 mt-n3 ba-card-text">{{ item.nombre }} {{ item.apellido }}</span>
      </div>
    </v-col>
    <!--end: contact header-->

    <!--begin: case header-->
    <v-col
      v-if="styleType === 'case'"
      class="ba-card-header"
    >
      <div>
        <v-row align-content="center">
          <v-icon
            color="primary"
            class="mx-2"
          >
            mdi-keyboard
          </v-icon>
          <span class="mx-2 my-2 ba-card-text">N°: {{ item.refnum }}</span>
        </v-row>
      </div>
    </v-col>
    <!--end: case header-->

    <!--begin: note header-->
    <v-col
      v-if="styleType === 'note'"
      class="ba-card-header px-0 py-0"
    >
      <div>
        <v-list-item class="pl-2 pt-0">
          <v-list-item-icon class="mr-3">
            <v-avatar
              :color="item.idusuario === $store.getters.getLoggedUser.id ? 'primary' : 'secondary'"
              size="36"
            >
              <span class="white--text">{{ getAvatar(item) }}</span>
            </v-avatar>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title><span class="ba-card-text"> {{ getName(item) }} </span></v-list-item-title>
            <v-list-item-subtitle>
              <h6>{{ item.isPublic ? 'Mensaje Publico' : 'Mensaje Privado'}}</h6>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-col>
    <!--end: note header-->

    <v-card-text
      class="font-weight-bold px-0"
      v-if="styleType === 'contact' || styleType === 'case'"
    >
      <v-list-item
        v-for="(header, index) of headers.filter(h => h.visible)"
        :key="index"
      >
        <v-list-item-icon class="mx-2 my-2">
          <v-icon class="my-1">
            {{ header.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>
            <div class="ba-card-text">
              <ba-sla-chip
                v-if="header.label === 'SLA'"
                :value="item[header.value]"
                :sla="getSlaValue(item)"
                :type="'list'"
              >
                {{ getSlaValue(item) ? header.label : $t('case.components.table.withoutSla')}}
              </ba-sla-chip>

              <div v-else>
                {{ header.label }}:
                <span>{{ parseList(item[header.value]) }}</span>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-card-text
      v-else
      class="font-weight-bold px-0"
    >
      <div class="px-5 py-3">
        <div
          class="mb-5"
          v-html="item.texto"
        ></div>
        <v-row justify="end">
          <span class="mr-2">{{ item.fechahora }}</span>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import BaChip from './SLAChip.vue'
export default {
  name: 'ba-card',
  components: {
    'ba-sla-chip': BaChip
  },
  props: {
    item: Object,
    headers: Array,
    hover: {
      type: Boolean,
      default: true
    },
    styleType: {
      type: String,
      default: 'contact'
    }
  },
  methods: {
    setItem () {
      this.$emit('selected', this.item)
    },
    getAvatar (item) {
      if (item.texto && item.texto.indexOf('Contact Center.') >= 0) {
        return 'CC'
      }
      if (item.nombre && item.apellido) {
        return item.nombre.charAt(0).toUpperCase() + item.apellido.charAt(0).toUpperCase()
      }
      return 'BA'
    },
    getName (item) {
      if (item.texto.indexOf('Contact Center.') >= 0) {
        return 'Contact Center'
      } else {
        return `${item.nombre} ${item.apellido}`
      }
    },
    parseList (item) {
      if (item && (typeof item === typeof [])) {
        return item.join(' - ')
      }
      return item
    },
    getSlaValue (item) {
      if (item.finalizado === 1 || !item.idworkflow || !item.idstageactual) {
        return false
      }
      return true
    }
  }
}
</script>

<style>
</style>
